<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A
        <number-value :value="ironOre" unit="\text{g}" />
        sample of an iron ore is dissolved in acid. The iron is then reduced to
        <chemical-latex content="Fe^{2+}" />
        and titrated with
        <number-value :value="KMnO4" unit="\text{mL}" />
        <chemical-latex content="0.01456 M KMnO4" />
        solution.
      </p>
      <p>
        <chemical-latex content="MnO4- + 5Fe^{2+}	+	8H+ -> Mn2+ + 5Fe^{3+} + 4H2O" />
      </p>
      <p class="mb-3">Calculate the results of the analysis in terms of the following:</p>

      <calculation-input
        v-model="inputs.studentAnswerFe"
        prepend-text="(a)"
        append-text="$\ce{\% Fe}$"
        :disabled="!allowEditing"
      />
      <calculation-input
        v-model="inputs.studentAnswerFe2O3"
        prepend-text="(b)"
        append-text="$\ce{\% Fe_2O_3}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUPEI2210A3_Q5',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswerFe: null,
        studentAnswerFe2O3: null,
      },
    };
  },
  computed: {
    ironOre() {
      return this.taskState.getValueBySymbol('ironOre').content;
    },
    KMnO4() {
      return this.taskState.getValueBySymbol('KMnO4').content;
    },
  },
};
</script>
